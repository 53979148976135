import './App.css';
import Bottle from './Components/Bottle/Bottle';
import React from 'react';


const colors = [
        {
            'name': 'red',
            'hex_code': '#FF0000'
        },
        {
            'name': 'blue',
            'hex_code': '#0000FF'
        },
        {
            'name': 'green',
            'hex_code': '#01DF01'
        },
        {
            'name': 'yellow',
            'hex_code': '#FFFF00'
        }
    ],
    bottleCount = 5,
    maxSectionCount = 4;

var usedColors = [];

class App extends React.Component {
    constructor() {
        super();

        this.state = {
            selectedBottle: 0,
            bottles: [],
            lastColorName: '',
            firstColor: '',
            bottleSections: this.getBottleSections(),
        };

        for (let i = 0; i < this.state.bottleSections.length; i++) {
            this.state.bottles.push(
                <Bottle
                    maxSectionCount={maxSectionCount}
                    bottleSections={this.state.bottleSections[i]}
                    bottleNumber={i}
                    onClick={() => this.setSelectedBottle(i)}
                    active={this.state.selectedBottle === i}
                />
            );
        }
    }

    setSelectedBottle(bottle) {
        let selectedBottleNumber = bottle.props.bottleNumber;

        if (this.state.selectedBottle !== null) {
            if (selectedBottleNumber !== this.state.selectedBottle) {

                if (bottle.props.bottleSections.length < bottle.props.maxSectionCount) {
                    //if (this.state.bottleSections[selectedBottleNumber].length === 0 || this.state.bottleSections[this.state.selectedBottle][0].props.color.hex_code === this.state.bottleSections[selectedBottleNumber][0].props.color.hex_code) {

                    let sameColorSections = 0,
                        firstColor = '';

                    for (let i = 0; i < this.state.bottleSections[this.state.selectedBottle].length; i++) {
                        if (firstColor === '') {
                            firstColor = this.state.bottleSections[this.state.selectedBottle][i].props.color.hex_code;
                        }

                        if (firstColor === this.state.bottleSections[this.state.selectedBottle][i].props.color.hex_code) {
                            sameColorSections++;
                        } else {
                            break;
                        }
                    }

                    for (let i = 0; i < sameColorSections; i++) {
                        let firstBottleSection = this.state.bottleSections[this.state.selectedBottle].shift();
                        this.state.bottleSections[selectedBottleNumber] = [firstBottleSection].concat(this.state.bottleSections[selectedBottleNumber]);
                    }


                    this.setState({
                        selectedBottle: null
                    });
                    // }
                }
            }
        } else {
            this.setState({
                selectedBottle: selectedBottleNumber
            });
        }
    }

    getBottleSections() {
        let bottleSections = [];

        usedColors = [];

        for (let x = 0; x < bottleCount - 1; x++) {
            let tempSections = [];

            for (let i = 0; i < maxSectionCount; i++) {
                let randomColor = this.getRandomColor(),
                    styles = {
                        backgroundColor: randomColor.hex_code,
                        height: 100 / maxSectionCount + '%',
                    };

                tempSections.push(
                    <div className='bottle_section' color={randomColor} style={styles}></div>
                );
            }

            bottleSections.push(tempSections);
        }

        bottleSections.push([]);

        return bottleSections;
    }

    getRandomColor() {
        let color = colors[Math.floor(Math.random() * colors.length)];

        let count = 0;
        for (let i = 0; i < usedColors.length; i++) {
            if (usedColors[i] === color.hex_code) {
                count++;
            }
        }
        console.log(color.hex_code, count, maxSectionCount);
        if (count < maxSectionCount) {
usedColors.push(color.hex_code);
            return color;
        }

        return this.getRandomColor();
    }

    render() {
        const {bottles, selectedBottle} = this.state;

        return (
            <div className="App">
                {bottles.map((bottle, index) => (
                    <Bottle
                        key={index}
                        maxSectionCount={bottle.props.maxSectionCount}
                        bottleSections={this.state.bottleSections[index]}
                        bottleNumber={index}
                        onClick={() => this.setSelectedBottle(bottle)}
                        active={selectedBottle === index}
                    />
                ))}
            </div>
        );
    }
}

export default App;
