import './Bottle.css';


function Bottle(props) {
    const bottleSections = typeof props.bottleSections !== "undefined" ? props.bottleSections : '',
        active = props.active;

    return (
        <div className={'bottle ' + (active ? 'active' : '')} onClick={props.onClick}>
            {bottleSections}
        </div>
    );
}

export default Bottle;